@import './variables';
@import './bootstrap/variables';

@import '~bootstrap/scss/bootstrap-utilities';

// Generate utilities for opacity
$utilities: (
	'opacity': (
		property: opacity,
		responsive: true,
		values: (
			0: 0,
			25: 0.25,
			50: 0.5,
			75: 0.75,
			100: 1,
		),
	),
);

//Generate utilites for Cursor
$utilities: map-merge(
	$utilities,
	(
		'cursor': (
			property: cursor,
			values: auto default pointer wait text move help not-allowed grab,
		),
	)
);

@import '~bootstrap/scss/bootstrap';
@import './react-tag-styles.scss';

@import './provideVariables.module.scss';

html,
body {
	width: 100%;
	--publishedGreen: #20c997;
	--notValidatedYellow: #ffc107;
	--deletedGrey: #6c757d;
	.modal-backdrop {
		z-index: 1201;
	}
	.modal {
		z-index: 1202;
	}
	mark {
		padding: 0;
	}
}

.btn-primary {
	--bs-btn-hover-color: white;
}

html {
	box-sizing: border-box;
	::-webkit-scrollbar {
		width: 7px;
		height: 7px;
	}

	::-webkit-scrollbar-thumb {
		background: #ababab;
		border-radius: 10px;
		border: 2px solid transparent;
		background-clip: padding-box; // <== make the border work
	}

	::-webkit-scrollbar-thumb:hover {
		border: 0;
	}

	::-webkit-scrollbar-track {
		background: transparent;
	}
}

@page { size: portrait; }
