// Figma mapping (remove later)
$Schriften_Menu_main: #5e5e5e;
$Thema_Impf: #ff9b0d;
$Thema_Nutzerbereich: #4195e1;
$Body_BG: #f6f8fb;

// Custom variables
$content-bg: $Body_BG;

$theme-vax-primary: $Thema_Impf;
$theme-user-primary: $Thema_Nutzerbereich;
$font-menu-main: $Schriften_Menu_main;

$transparent-white-50: rgba(255, 255, 255, 0.5);
$transparent-gray-50: rgba(94, 94, 94, 0.5);
