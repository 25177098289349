// non-bootstrap, custom variables
@import './_variables';

// our bootstrap customizations
@import './bootstrap/_variables';

// default bootstrap
@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';

// Export all SCSS variables, they should provided for styled-component
:export {
	// Export bootstrap variables
	bodyColor: $body-color;
	blue: $blue;
	indigo: $indigo;
	purple: $purple;
	pink: $pink;
	red: $red;
	orange: $orange;
	yellow: $yellow;
	green: $green;
	teal: $teal;
	cyan: $cyan;
	white: $white;
	gray100: $gray-100;
	gray200: $gray-200;
	gray300: $gray-300;
	gray400: $gray-400;
	gray500: $gray-500;
	gray600: $gray-600;
	gray700: $gray-700;
	gray800: $gray-800;
	gray900: $gray-900;
	gray: $gray-600;
	grayDark: $gray-800;

	// Export custom variables for styled-components (globally for all components)
	themePrimary: $theme-vax-primary;
	transparentWhite50: $transparent-white-50;
	transparentGray50: $transparent-gray-50;
	fontMenuMain: $font-menu-main;
	contentBg: $content-bg;
}
